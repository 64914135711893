export default class extends HTMLElement {
  connectedCallback () {
    for (const link of [...this.querySelectorAll('a')].reverse()) {
      if (document.location.pathname.startsWith(link.dataset.path || link.pathname) && !link.getAttribute('href').startsWith('http')) {
        link.classList.add(...(this.dataset.activeClass ? this.dataset.activeClass.split(' ') : ['bg-base-300']))

        if (this.dataset.scrollIntoView) {
          link.scrollIntoView({ block: 'nearest', inline: 'nearest' })
        }

        break
      }
    }
  }
}
