export default class extends HTMLElement {
  connectedCallback () {
    this.initPaddle()

    this.button.addEventListener('click', (e) => {
      e.preventDefault()

      window.Paddle.Checkout.open({
        product: this.dataset.productId,
        marketingConsent: '1',
        email: this.dataset.email,
        successCallback: () => {
          if (this.dataset.redirectOnSuccess) {
            const link = document.createElement('a')

            link.href = this.dataset.redirectOnSuccess

            document.body.append(link)

            link.click()
          } else {
            setTimeout(() => {
              if (confirm('Refresh the page now to see your plan?')) {
                document.location.reload()
              }
            }, 2000)
          }
        }
      })
    })
  }

  initPaddle () {
    const paddleJsUrl = 'https://cdn.paddle.com/paddle/paddle.js'

    if (document.querySelector(`[src="${paddleJsUrl}"]`)) {
      return
    }

    const script = document.createElement('script')

    script.src = paddleJsUrl
    script.onload = () => {
      if (this.dataset.sandbox === 'true') {
        window.Paddle.Environment.set('sandbox')
      }

      window.Paddle.Setup({ vendor: parseInt(this.dataset.vendorId) })
    }

    document.head.appendChild(script)
  }

  get button () {
    return document.getElementById(this.dataset.buttonId)
  }
}
