import { actionable } from '@github/catalyst/lib/actionable'
import { target, targetable } from '@github/catalyst/lib/targetable'

export default actionable(targetable(class extends HTMLElement {
  static [target.static] = [
    'loading',
    'icon',
    'filePreviews',
    'submitButton'
  ]

  connectedCallback () {
    this.inputClone = this.input.cloneNode(true)

    this.addEventListener('drop', this.onDrop)
    this.addEventListener('dragover', (e) => e.preventDefault())

    this.form.addEventListener('submit', this.toggleLoading)
    this.form.addEventListener('turbo:submit-end', this.toggleLoading)
    this.form.addEventListener('turbo:submit-end', this.resetForm)
  }

  disconnectedCallback () {
    this.form.removeEventListener('submit', this.toggleLoading)
    this.form.removeEventListener('turbo:submit-end', this.toggleLoading)
    this.form.removeEventListener('turbo:submit-end', this.resetForm)
  }

  onDrop (e) {
    e.preventDefault()

    const acceptedFileTypes = new DataTransfer()

    Array.from(e.dataTransfer.files).forEach(file => {
      if (file.type === 'application/pdf') {
        acceptedFileTypes.items.add(file)
      }
    })

    const newInput = this.inputClone.cloneNode(true)

    this.input.files = acceptedFileTypes.files
    this.input.id = ''
    this.form.append(newInput)

    this.updateFilesList()
  }

  onSelectFiles (e) {
    e.preventDefault()

    this.updateFilesList()
  }

  updateFilesList () {
    const newInput = this.inputClone.cloneNode(true)

    const files = [...this.querySelectorAll('input[type="file"]')].map((e) => [...e.files]).flat()
    const totalFilesSize = files.reduce((acc, file) => acc + file.size, 0)

    if (totalFilesSize > 100 * 1024 * 1024) {
      alert('Total files size should be less than 100MB.')
      this.input.files = null
      return
    }

    this.input.id = ''
    this.form.append(newInput)

    this.filePreviews.innerHTML = Array.from(files).map((file) => {
      return this.dataset.filePreviewHtml.replace('{{filename}}', file.name)
    }).join('')

    this.filePreviews.classList.toggle('hidden', files.length < 1)
    this.submitButton.disabled = files.length < 2
  }

  resetForm = () => {
    this.filePreviews.innerHTML = ''
    this.filePreviews.classList.add('hidden')
    this.submitButton.classList.add('hidden')
  }

  toggleLoading = () => {
    this.loading.classList.toggle('hidden')
    this.icon.classList.toggle('hidden')
    this.submitButton.classList.toggle('btn-disabled')
    this.classList.toggle('opacity-50')
  }

  get form () {
    return this.querySelector('form')
  }

  get input () {
    return this.form.querySelector('#file_input')
  }
}))
