export default class extends HTMLElement {
  static observedAttributes = ['data-active']

  attributeChangedCallback (name, oldValue, newValue) {
    if (name === 'data-active') {
      if (newValue === 'true' && oldValue !== newValue) {
        if (document.contains(this)) {
          this.start()
        }
      } else {
        this.stop()
      }
    }
  }

  connectedCallback () {
    this.time = 40

    if (this.dataset.active === 'true') {
      this.start()
    }

    this.addEventListener('click', () => {
      if (this.dataset.active !== 'true') {
        this.time = 80

        this.activate()
      }
    })

    this.querySelector('a').addEventListener('click', (e) => {
      e.preventDefault()
    })
  }

  disconnectedCallback () {
    this.stop()
  }

  start () {
    this.interval ||= setInterval(() => {
      this.updateProgress()

      if (parseInt(this.progressBar.value) >= 100) {
        this.moveToNext()
      }
    }, this.time || 40)
  }

  moveToNext () {
    const nextItem = this.items[(this.items.indexOf(this) + 1) % 3]

    nextItem.activate()
  }

  activate () {
    this.items.forEach((e) => {
      e.dataset.active = false
      e.classList.remove('bg-base-200/70')
    })

    this.dataset.active = true
    this.classList.add('bg-base-200/70')

    const step = document.getElementById(this.dataset.stepId)
    const cards = [...step.parentNode.children]
    cards.forEach((e) => e.classList.add('hidden'))
    step.classList.remove('hidden')
  }

  stop () {
    if (this.interval) {
      clearInterval(this.interval)
      this.interval = null
    }

    this.progressBar.value = 0
  }

  updateProgress () {
    this.progressBar.value = parseFloat(this.progressBar.value) + 0.3
  }

  get items () {
    return [...this.parentNode.querySelectorAll('carousel-item')]
  }

  get progressBar () {
    return this.querySelector('progress')
  }
}
