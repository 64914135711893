export default class extends HTMLElement {
  connectedCallback () {
    if (!/android|iphone|ipad/i.test(navigator.userAgent)) {
      window.addEventListener('scroll', this.onScroll)
    }
  }

  disconnectedCallback () {
    window.removeEventListener('scroll', this.onScroll)
  }

  onScroll = () => {
    let firstVisible

    this.elements.forEach((el) => {
      const title = document.getElementById(el.href.split('#')[1])

      if (this.isInViewport(title)) {
        firstVisible ||= el
      }
    })

    if (firstVisible) {
      this.elements.forEach((e) => e.classList.toggle('bg-base-200', e === firstVisible))

      if (!this.isInViewport(firstVisible)) {
        firstVisible.scrollIntoView({ block: 'center' })
      }
    }
  }

  isInViewport (element, offset = 0) {
    const rect = element.getBoundingClientRect()

    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom + offset <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    )
  }

  get elements () {
    return [...this.querySelectorAll('[href^="#"]')]
  }
}
