import { annotate } from 'rough-notation'

export default class extends HTMLElement {
  connectedCallback () {
    this.annotation = annotate(this, { type: this.dataset.type || 'underline', color: this.dataset.color || '#fbbd23', brackets: this.dataset.brackets || ['left', 'right'], strokeWidth: this.dataset.strokeWidth || '1' })

    this.annotation.show()
  }

  redraw () {
    this.annotation.hide()
    this.annotation.animate = false
    this.annotation.show()
  }
}
