export default class extends HTMLElement {
  connectedCallback () {
    this.checkbox = this.querySelector('input[type="checkbox"]')
    this.embedElement = document.querySelector(this.dataset.embedTagName)
    this.embedAttributeName = this.dataset.name
    this.embedAttributeEnableValue = this.embedElement.dataset[this.embedAttributeName] || this.dataset.value

    this.checkbox.addEventListener('change', this.toggleAttributeValue.bind(this))
  }

  toggleAttributeValue () {
    const { dataset } = this.embedElement
    const { checked } = this.checkbox
    const { embedAttributeName, embedAttributeEnableValue } = this

    const isBooleanToggle = ['true', 'false'].includes(embedAttributeEnableValue)
    const isStringToggle = typeof embedAttributeEnableValue === 'string' && embedAttributeEnableValue.length > 0

    if (isBooleanToggle) {
      dataset[embedAttributeName] = checked
    } else if (isStringToggle) {
      if (checked) {
        dataset[embedAttributeName] = embedAttributeEnableValue
      } else {
        delete dataset[embedAttributeName]
      }
    }
  }
}
