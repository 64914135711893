export default class extends HTMLElement {
  connectedCallback () {
    this.input.addEventListener('input', () => {
      this.update()
    })
  }

  update () {
    console.log([...this.carousel.children])
    return [...this.carousel.children].forEach((el, index) => {
      console.log(Math.round(parseInt(this.input.value) / 33.33))
      el.classList.toggle('hidden', index !== Math.round(parseInt(this.input.value) / 33.33))
    })
  }

  get input () {
    return document.getElementById(this.dataset.inputId)
  }

  get carousel () {
    return document.getElementById(this.dataset.carouselId)
  }
}
