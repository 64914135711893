import Plausible from 'plausible-tracker'

const plausible = Plausible({
  apiHost: 'https://plausible.docuseal.tech',
  domain: 'www.docuseal.co'
})

export default class extends HTMLElement {
  connectedCallback () {
    fetch('/checkout', {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        session_id: document.location.search.split('=')[1]
      })
    }).then((resp) => {
      if (resp.status !== 200) {
        document.querySelector('#error').classList.remove('hidden')
      } else {
        document.querySelector('#success').classList.remove('hidden')

        plausible.trackEvent('Subscribed')
      }
    }).catch(() => {
      document.querySelector('#error').classList.remove('hidden')
    }).finally(() => {
      document.querySelector('#loading').classList.add('hidden')
    })
  }
}
