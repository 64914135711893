export default class extends HTMLElement {
  connectedCallback () {
    this.type = this.dataset.type || 'checkbox'

    if (this.type === 'checkbox') {
      const input = this.querySelector('input')

      input.addEventListener('change', (e) => {
        e.preventDefault()

        document.querySelectorAll(this.dataset.selector).forEach((el) => {
          el.classList.toggle('hidden')
        })
      })
    } else if (this.type === 'radio') {
      this.querySelectorAll('input[type="radio"]').forEach((input) => {
        input.addEventListener('change', (e) => {
          e.preventDefault()

          document.querySelectorAll(this.dataset.selector).forEach((el) => {
            el.classList.toggle('hidden')
          })
        })
      })
    }
  }
}
