
export default class extends HTMLElement {
  connectedCallback () {
    this.showMoreButton = this.getElementsByTagName('button')[0]
    this.testimonialContainer = document.querySelector('.testimonial-container')
    this.testimonialBlurMasks = document.querySelectorAll('.testimonial-blur-mask')
    this.hiddenTestimonials = document.querySelectorAll('.testimonial-hidden')

    this.showMoreButton.addEventListener('click', (e) => {
      e.preventDefault()

      this.showMoreButton.classList.toggle('hidden')
      this.testimonialContainer.classList.replace('h-32', 'h-auto')

      this.testimonialBlurMasks.forEach((testimonialBlurMask) => {
        testimonialBlurMask.classList.toggle('hidden')
      })

      document.querySelectorAll('rough-notation').forEach((e) => e.redraw())
    })
  }
}
